import { z } from 'zod';
import { validateKeyPix, validateUserName } from '../../helpers/utils';
import {
	isValidCPF,
	isValidMobilePhone,
} from '@brazilian-utils/brazilian-utils';

export const UpdateNick = z.object({
	nick: z
		.string()
		.min(1, 'Preencha o campo')
		.toLowerCase()
		.refine((val) => !val || validateUserName(val), {
			message: 'Apelido inválido!',
		})
		.or(z.literal('')),
});

export const UpdateEmail = z.object({
	email: z
		.string()
		.min(1, 'Preencha o campo')
		.email('Informe um e-mail válido')
		.toLowerCase()
		.or(z.literal('')),
});

export const UpdatePhone = z.object({
	phone: z
		.string()
		.min(1, 'Preencha o campo')
		.refine((val) => !val || isValidMobilePhone(val), {
			message: 'Informe um telefone válido',
		})
		.or(z.literal('')),
});

export const UpdatePassword = z
	.object({
		oldPassword: z
			.string()
			.min(1, 'Preencha o campo')
			.refine((val) => val.length > 7, {
				message: 'A senha deve ter pelo menos 8 caracteres!',
			})
			.refine((val) => /[A-Z]/.test(val), {
				message: 'A senha deve ter pelo menos uma letra maiúscula!',
			})
			.refine((val) => /[a-z]/.test(val), {
				message: 'A senha deve ter pelo menos uma letra minúscula!',
			})
			.refine((val) => /\d/.test(val), {
				message: 'A senha deve ter pelo menos um dígito!',
			})
			.refine((val) => /[^a-zA-Z0-9]/.test(val), {
				message: 'A senha deve ter pelo menos um caractere especial!',
			})
			.or(z.literal('')),
		password: z
			.string()
			.min(1, 'Preencha o campo')
			.refine((val) => val.length > 7, {
				message: 'A senha deve ter pelo menos 8 caracteres!',
			})
			.refine((val) => /[A-Z]/.test(val), {
				message: 'A senha deve ter pelo menos uma letra maiúscula!',
			})
			.refine((val) => /[a-z]/.test(val), {
				message: 'A senha deve ter pelo menos uma letra minúscula!',
			})
			.refine((val) => /\d/.test(val), {
				message: 'A senha deve ter pelo menos um dígito!',
			})
			.refine((val) => /[^a-zA-Z0-9]/.test(val), {
				message: 'A senha deve ter pelo menos um caractere especial!',
			})
			.or(z.literal('')),
		confirmPassword: z
			.string()
			.min(1, 'Preencha o campo')
			.or(z.literal('')),
	})
	.refine(
		(data) =>
			data.confirmPassword === '' ||
			data.password === data.confirmPassword,
		{
			message: 'As senhas não coincidem',
			path: ['confirmPassword'],
		}
	);

export const UpdatePixKey = z.object({
	pixKeyDocumentNumber: z
		.string()
		.nullable()
		.optional()
		.refine((val) => !val || val.length > 14 || isValidCPF(val), {
			message: 'Informe um CPF válido.',
		})
		.or(z.literal('')),
	pixKeyEmail: z
		.string()
		.email({ message: 'Informe um e-mail válido' })
		.optional()
		.or(z.literal('')),
	pixKeyPhone: z
		.string()
		.nullable()
		.optional()
		.refine((val) => !val || isValidMobilePhone(val), {
			message: 'Informe um telefone válido',
		})
		.or(z.literal('')),
	pixKeyRandom: z
		.string()
		.nullable()
		.optional()
		.refine((val) => !val || validateKeyPix(val), {
			message: 'Informe uma chave aleatória válida',
		})
		.or(z.literal('')),
});
