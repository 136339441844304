import React, {
	createContext,
	useState,
	useContext,
	ReactNode,
	useEffect,
	useRef,
} from 'react';
import { useApi } from '../../hooks/useApi';
import { useTokenStore } from '../../store/token';
import {
	areMandatoryQuestionsAnswered,
	CurrencyFormatter,
	generateUrl,
	isMobileWeb,
	sentryCapture,
	showWalletAndBalance,
} from '../../helpers/utils';
import { SentryError } from '../../types/enums';
import Toast from '../../components/ui/toast';
import { toast } from 'sonner';
import Button from '../../components/ui/button';
import ModalEnroll from '../../components/Modal/Enroll';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBalanceStore } from '../../store/balance';
import { Warning } from '../../components/Icons/Warning';
import { useModalBalanceAlertStore } from '../../store/modalBalanceAlert';
import { useAuthContext } from '../Auth/AuthContext';
import { useModalLoginStore } from '../../store/modalLogin';
import { shared } from '../../helpers/utilsUi';
import { Share } from '@capacitor/share';
import { Clipboard } from '@capacitor/clipboard';
import { Capacitor } from '@capacitor/core';
import { Media, MediaSaveOptions } from '@capacitor-community/media';
import { App } from '@capacitor/app';
import { FutProIcon } from '../../components/Icons/FutProIcon';

interface EnrollContextType {
	enroll: any;
	select: any;
	enrollOptions: any;
	tournament: any;
	optionTournament: any;
	optionSelection: number;
	selectEnroll: (item: any) => void;
	enrollOption: (data: any) => void;
	initialSelectEnroll: (item: any) => void;
	depositReturnEnroll: (item: any) => void;
	SetOptionTournament: (data: any) => void;
	SetTournament: (data: any) => void;
	SetOptionSelection: (value: number) => void;
	SetLeague: (data: any) => void;
	SetError: (message: string) => void;
	openModalEnroll: () => void;
	closeModalEnroll: () => void;
	clearEnroll: () => void;
	clearOptionTournament: () => void;
	clearTournament: () => void;
	clearOptionSelection: () => void;
	clearLeague: () => void;
	SetTabs: (tabs: string) => void;
}

export const EnrollContext = createContext<EnrollContextType | undefined>(
	undefined
);

interface EnrollProviderProps {
	children: ReactNode;
}

export const EnrollProvider: React.FC<EnrollProviderProps> = ({ children }) => {
	const api = useApi();
	const { user } = useAuthContext();
	const { openModalLogin, modalLogin, closeModalLogin } =
		useModalLoginStore();
	const navigate = useNavigate();
	const location = useLocation();
	const { modal, openModal, closeModal, enrollsInfoModal } =
		useModalBalanceAlertStore();
	const currentBalance = useBalanceStore((state) => state.balance);
	const token = useTokenStore((state: any) => state.token);
	const ref = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [changedModal, setChangedModal] = useState(false);
	const [buttonShow, setButtonShow] = useState(false);
	const [sucessModal, setSucessModal] = useState(false);
	const [optionSelection, setOptionSelection] = useState<number>(0);
	const [tournament, setTournament] = useState<any>();
	const [league, setLeague] = useState<any[]>([]);
	const [optionTournament, setOptionTournament] = useState<string[]>([]);
	const [select, setSelect] = useState<string[]>([]);
	const [enroll, setEnroll] = useState<any[]>([]);
	const [enrollOptions, setEnrollOptions] = useState<string[]>([]);
	const entryAmt = enroll.reduce((acc, league) => {
		return acc + parseFloat(league.entryAmt);
	}, 0);
	const [disableButton, setDisableButton] = useState(true);
	const [error, setError] = useState('');
	const [openTab, setOpenTab] = useState('');
	const [image, setImage] = useState('');
	const [isSaving, setIsSaving] = useState(false);

	const textShared =
		'Junte-se a mim no FUTPro e concorra a prêmios toda semana.\n\nAcesse: www.futpro.com.br';

	useEffect(() => {
		if (league.length > 0) {
			const pattern = /^\/tournaments\/[a-fA-F0-9-]{36}$/;
			if (pattern.test(location.pathname)) {
				if (tournament.tournamentStatus === 0) {
					if (league.length > 0 && openTab === 'Perguntas') {
						setButtonShow(true);
					} else {
						setButtonShow(false);
					}
				}
			} else {
				clearAllState();
				setButtonShow(false);
			}
		}
	}, [location.pathname, league, openTab]);

	const clearAllState = () => {
		setEnroll([]);
		setEnrollOptions([]);
		setOptionTournament([]);
		setTournament(undefined);
		setOptionSelection(0);
		setSelect([]);
		setLeague([]);
		setOpenTab('');
		setChangedModal(false);
		setImage('');
		setIsSaving(false);
	};

	const SetError = (message: string) => {
		setError(message);
	};

	const enrollOption = (data: any) => {
		setEnrollOptions(data);
	};
	const SetOptionTournament = (data: any) => {
		setOptionTournament(data);
	};
	const SetTournament = (data: any) => {
		setTournament(data);
	};
	const SetOptionSelection = (value: number) => {
		setOptionSelection(value);
	};
	const SetLeague = (data: any) => {
		setLeague(data);
	};
	const SetTabs = (data: string) => {
		setOpenTab(data);
	};
	const clearEnroll = () => {
		setEnroll([]);
	};
	const clearOptionTournament = () => {
		setOptionTournament([]);
	};
	const clearTournament = () => {
		setTournament({});
	};
	const clearLeague = () => {
		setLeague([]);
	};
	const openModalEnroll = () => {
		if (user) {
			setChangedModal(true);
		} else {
			openModalLogin(true, `/tournaments/${tournament.tournamentId}`);
		}
	};
	const clearOptionSelection = () => {
		setOptionSelection(0);
	};
	const closeModalEnroll = () => {
		setChangedModal(false);
	};
	const closeSucessModalEnroll = () => {
		setSucessModal(false);
		clearEnroll();
		clearTournament();
		clearOptionTournament();
		setEnrollOptions([]);
		navigate('/tournaments');
	};

	const selectEnroll = (item: any) => {
		setSelect((prevSelect) => {
			if (prevSelect.includes(item.leagueId)) {
				return prevSelect.filter((id) => id !== item.leagueId);
			} else {
				return [...prevSelect, item.leagueId];
			}
		});
		setEnroll((prevOptions: any) => {
			const exists = prevOptions.some(
				(league: any) => league.leagueId === item.leagueId
			);
			if (exists) {
				const updatedOptions = prevOptions.filter(
					(league: any) => league.leagueId !== item.leagueId
				);
				return updatedOptions;
			} else {
				return [...prevOptions, item];
			}
		});
	};
	const initialSelectEnroll = (item: any) => {
		setSelect([item.leagueId]);
		setEnroll([item]);
	};
	const depositReturnEnroll = (item: any) => {
		setSelect(item.map((select: any) => select.leagueId));
		setEnroll(item);
	};

	const entryLeague = async (leagueId: string) => {
		try {
			const res = await api.findEnroll(
				leagueId,
				tournament.tournamentId,
				token
			);
			if (!res?.success) {
				throw new Error(res.message);
			}
			return res;
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'entryLeagueValidation', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	const ensureAlbum = async () => {
		if (Capacitor.isNativePlatform()) {
			const { albums } = await Media.getAlbums();
			let demoAlbum = undefined;
			if (Capacitor.getPlatform() === 'android') {
				const albumsPath = (await Media.getAlbumsPath()).path;
				demoAlbum = albums.find(
					(a) =>
						a.name === 'FUTPro' &&
						a.identifier.startsWith(albumsPath)
				);
			}

			if (!demoAlbum) {
				await Media.createAlbum({ name: 'FUTPro' });
				return;
			}

			return demoAlbum.identifier;
		}
	};

	const handleDownload = async () => {
		if (Capacitor.isNativePlatform()) {
			try {
				if (image === '') {
					setIsSaving(true);
					let opts: MediaSaveOptions;
					if (Capacitor.getPlatform() === 'android') {
						opts = {
							path: tournament.sharedUrl,
							albumIdentifier: await ensureAlbum(),
						};
					} else {
						opts = {
							path: tournament.sharedUrl,
						};
					}
					const savePhoto = await Media.savePhoto(opts);
					const path = 'file://' + savePhoto.filePath!;
					setImage(path);
					setIsSaving(false);
					toast(
						<Toast variant="success">
							A imagem salva na galeria.
						</Toast>
					);
					return path;
				} else {
					return image;
				}
			} catch (err: any) {
				setIsSaving(false);
				sentryCapture(error, 'handleDownload', SentryError.Error);
			} finally {
				setIsSaving(false);
			}
		} else {
			const link = document.createElement('a');
			link.href = tournament.sharedUrl;
			link.setAttribute('download', 'imagem.png');
			link.click();
		}
	};

	const handleSave = async () => {
		setIsLoading(true);
		for (const findEnroll of enroll) {
			const findLeague = league.find(
				(id) => id.league.id === findEnroll.leagueId
			);

			if (!findLeague.multipleEntry) {
				const res = await entryLeague(findLeague.league.id);
				if (!res?.success) {
					setIsLoading(false);
					return;
				}
			}
		}

		if (parseFloat(currentBalance) < entryAmt) {
			if (showWalletAndBalance()) {
				openModal(true);
				closeModalEnroll();
				setIsLoading(false);
				return;
			}
		}

		try {
			const body = {
				tournamentId: tournament.tournamentId,
				entryAmt,
				leagues: enroll,
				optionsSelect: enrollOptions,
			};

			const res = await api.createNewEnroll(body, token);
			if (!res?.success) {
				throw new Error(res.message);
			}
			toast(
				<Toast variant="success">Entrada Realizada! Boa sorte!</Toast>
			);
			setSucessModal(true);
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'sendTokenValidation', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};
	const enrollCount = enrollOptions.length;

	useEffect(() => {
		if (!areMandatoryQuestionsAnswered(optionTournament, enrollOptions)) {
			setDisableButton(true);
			setError('Responda primeiro as perguntas obrigatórias.');
			return;
		}

		if (enrollCount === 0) {
			setDisableButton(true);
			setError(
				`Responda no máximo ${optionSelection} perguntas para participar`
			);
		}

		setDisableButton(false);
		setError('');
	}, [enroll, enrollOptions]);

	const copyToClipboard = async (copyPaste: string) => {
		try {
			await Clipboard.write({ string: copyPaste });
			toast(<Toast variant="success">Copiado com sucesso!</Toast>);
		} catch (err) {
			console.log('Falha ao copiar o texto', err);
			toast(<Toast variant="error">Falha ao copiar.</Toast>);
		}
	};

	useEffect(() => {
		const setupListener = async () => {
			App.addListener('backButton', (event) => {
				console.log('Botão voltar pressionado');
				const pattern = /^\/tournaments\/[a-fA-F0-9-]{36}$/;

				if (pattern.test(location.pathname) && !user) {
					if (modalLogin) {
						console.log('Fechando modal de login');
						closeModalLogin();
					} else if (changedModal) {
						console.log('Fechando modal de inscrição');
						closeModalEnroll();
					} else {
						console.log('Nenhum modal aberto');
					}
				}
			});
		};

		setupListener();
	}, [modalLogin, changedModal]);

	return (
		<EnrollContext.Provider
			value={{
				enroll,
				select,
				enrollOptions,
				optionTournament,
				tournament,
				optionSelection,
				selectEnroll,
				enrollOption,
				SetOptionTournament,
				SetTournament,
				SetOptionSelection,
				SetLeague,
				openModalEnroll,
				closeModalEnroll,
				clearEnroll,
				clearOptionTournament,
				clearTournament,
				clearOptionSelection,
				initialSelectEnroll,
				depositReturnEnroll,
				clearLeague,
				SetError,
				SetTabs,
			}}
		>
			{children}
			{buttonShow && (
				<div className="fixed flex items-center justify-center bottom-[80px] left-0 right-0 px-3.5 w-full lg:max-w-screen-xl lg:mx-auto duration-500 transform">
					<Button
						className="w-full px-5 py-3"
						onClick={openModalEnroll}
						disabled={disableButton}
					>
						{error
							? error
							: enrollCount === optionSelection
							? 'Confirmar inscrição'
							: `Confirmar inscrição ${enrollCount} de ${optionSelection}`}
					</Button>
				</div>
			)}
			{user && tournament && (
				<>
					<ModalEnroll
						id="modalenroll"
						open={changedModal}
						onClose={closeModalEnroll}
					>
						<div className="flex flex-col gap-6">
							<div className="flex flex-col">
								<div className="self-stretch text-primary text-xl font-bold font-bahnschrift pb-2.5">
									Confirme sua inscrição
								</div>

								{enroll.length > 0 && (
									<>
										<div className="text-black text-xs font-normal font-bahnschrift pb-2">
											Ligas selecionadas:
										</div>
										<div className="flex flex-col gap-2 pb-4">
											{enroll.map((enroll, index) => (
												<div
													key={index}
													className="flex px-2.5 py-2 bg-black rounded-lg border border-primary justify-between items-center bg-no-repeat bg-right"
													onClick={() => {
														selectEnroll({
															leagueId:
																enroll.leagueId,
															leagueName:
																enroll.leagueName,
															entryAmt:
																enroll.entryAmt,
															prizeAmt:
																enroll.prizeAmt,
														});
													}}
												>
													<div>
														<div className="text-white text-sm font-bold font-bahnschrift leading-snug">
															{enroll.leagueName}
														</div>

														<div className="text-white text-xs font-bold font-bahnschrift leading-snug flex items-center gap-1">
															Ganhe até{' '}
															<span className="text-[15px] flex items-center gap-1">
																{showWalletAndBalance() ? (
																	'R$ '
																) : (
																	<FutProIcon
																		style="w-3.5 h-3.5"
																		color="#ffffff"
																	/>
																)}
																{CurrencyFormatter(
																	Number(
																		enroll.prizeAmt
																	)
																)}
															</span>
														</div>
													</div>

													<div className="flex flex-row gap-0.5">
														<span className="text-white text-[10px] font-bold font-bahnschrift leading-[14px] flex items-center gap-1">
															{showWalletAndBalance() ? (
																'R$ '
															) : (
																<FutProIcon
																	style="w-3.5 h-3.5"
																	color="#ffffff"
																/>
															)}
														</span>
														<span className="text-white text-xs font-bold font-bahnschrift leading-[14px]">
															{CurrencyFormatter(
																Number(
																	enroll.entryAmt
																)
															)}
														</span>
													</div>
												</div>
											))}
										</div>
									</>
								)}

								{league.filter(
									(league: any) =>
										!enroll.some(
											(exclude) =>
												exclude.leagueId ===
												league.league.id
										)
								).length !== 0 && (
									<>
										<div className="text-black text-xs font-normal font-bahnschrift pb-2">
											Ligas Disponiveis:
										</div>
										<div className="flex flex-col gap-2 pb-4">
											{league
												.filter(
													(league: any) =>
														!enroll.some(
															(exclude) =>
																exclude.leagueId ===
																league.league.id
														)
												)
												.map((league, index) => (
													<div
														key={index}
														className="flex px-2.5 py-2 bg-[#727272] rounded-lg justify-between items-center bg-no-repeat bg-right"
														onClick={() => {
															selectEnroll({
																leagueId:
																	league
																		.league
																		.id,
																leagueName:
																	league
																		.league
																		.title,
																entryAmt:
																	league.entryAmt,
																prizeAmt:
																	league.prizeAmt,
															});
														}}
													>
														<div>
															<div className="text-white text-sm font-bold font-bahnschrift leading-snug">
																{
																	league
																		.league
																		.title
																}
															</div>
															<div className="text-white text-xs font-bold font-bahnschrift leading-snug flex items-center gap-1">
																Ganhe até{' '}
																<span className="text-[15px] flex items-center gap-1">
																	{showWalletAndBalance() ? (
																		'R$ '
																	) : (
																		<FutProIcon
																			style="w-3.5 h-3.5"
																			color="#ffffff"
																		/>
																	)}
																	{CurrencyFormatter(
																		Number(
																			league.prizeAmt
																		)
																	)}
																</span>
															</div>
														</div>

														<div className="flex flex-row gap-0.5">
															<span className="text-white text-[10px] font-bold font-bahnschrift leading-[14px] flex items-center gap-1">
																{showWalletAndBalance() ? (
																	'R$ '
																) : (
																	<FutProIcon
																		style="w-3.5 h-3.5"
																		color="#ffffff"
																	/>
																)}
															</span>
															<span className="text-white text-xs font-bold font-bahnschrift leading-[14px]">
																{CurrencyFormatter(
																	Number(
																		league.entryAmt
																	)
																)}
															</span>
														</div>
													</div>
												))}
										</div>
									</>
								)}

								<div className="flex items-center gap-1">
									<span className="text-black text-xs font-normal font-bahnschrift">
										{showWalletAndBalance()
											? 'Total a pagar:'
											: 'Total:'}
									</span>
									<span className="text-black text-[15px] font-bold font-bahnschrift flex items-center gap-1">
										{' '}
										{showWalletAndBalance() ? (
											'R$ '
										) : (
											<FutProIcon
												style="w-3.5 h-3.5"
												color="#000000"
											/>
										)}
										{CurrencyFormatter(entryAmt)}
									</span>
								</div>
							</div>

							<Button
								className="w-full px-5 py-3"
								textCustom="text-custom-white"
								isLoading={isLoading}
								onClick={handleSave}
								disabled={enroll.length === 0}
							>
								{enroll.length === 0
									? 'Selecione ao menos uma liga'
									: enrollCount === optionSelection
									? 'Confirmar inscrição'
									: `Confirmar inscrição ${enrollCount} de ${optionSelection}`}
							</Button>
						</div>
					</ModalEnroll>

					<ModalEnroll
						id="modalBalance"
						open={modal}
						onClose={closeModal}
					>
						<div className="flex flex-col gap-6 items-center">
							<Warning />

							<p className="w-80 text-sm text-center text-custom-gray2 font-bahnschrift">
								O saldo da sua carteira é de R${' '}
								{CurrencyFormatter(Number(currentBalance))},
								deseja efetuar um depósito?
							</p>

							<div className="flex flex-row justify-between gap-2 w-full">
								<Button
									onClick={closeModal}
									variant="help"
									textCustom="text-primary text-sm font-semibold"
									className="w-20 py-2 px-3.5"
								>
									Não
								</Button>
								<div className="w-full">
									<Button
										onClick={() => {
											closeModal();
											enrollsInfoModal(
												`/tournaments/${tournament.tournamentId}`,
												entryAmt -
													parseFloat(currentBalance),
												enroll,
												enrollOptions
											);
											navigate(`/wallet/deposit`);
										}}
										className={'w-full h-12'}
									>
										Efetuar depósito
									</Button>
								</div>
							</div>
						</div>
					</ModalEnroll>

					<ModalEnroll
						id="sucessModal"
						open={sucessModal}
						onClose={closeSucessModalEnroll}
						outsideClick
					>
						<div className="flex flex-col gap-6">
							<div className="flex flex-col gap-1 text-center">
								<span className="text-black text-xs font-semibold font-bahnschrift leading-none">
									Inscrição concluida.
								</span>
								<span className="text-black text-xs font-normal font-bahnschrift leading-3">
									Compartilhe com seus amigos
								</span>
							</div>

							<img
								className="lg:h-48 w-full h-48 object-scale-down z-0"
								alt={tournament.tournamentName}
								src={tournament.sharedUrl}
							/>

							<textarea
								disabled
								className="custom-scroll disabled:bg-white disabled:text-black resize-none overflow-y-scroll h-20 px-1.5 py-2 rounded border border-[#d3d3d3] flex-col justify-start items-start gap-6 inline-flex self-stretch text-black text-xs font-light font-bahnschrift"
							>
								{textShared}
							</textarea>

							<div className="w-full flex flex-col gap-2">
								<div className="grid grid-cols-2 gap-2">
									<Button
										onClick={() => {
											copyToClipboard(textShared);
										}}
										className="px-3.5 py-3 font-bahnschrift text-center"
										textCustom="text-sm"
									>
										Copiar texto
									</Button>
									<Button
										onClick={handleDownload}
										className="px-3.5 py-3 font-bahnschrift text-center"
										textCustom="text-sm"
										isLoading={isSaving}
										disabled={isSaving}
									>
										Baixar imagem
									</Button>
								</div>
								<Button
									onClick={() => {
										const link =
											document.createElement('a');
										link.href = 'https://www.instagram.com';
										link.target = '_blank';
										link.click();
									}}
									className="px-3.5 py-3 font-bahnschrift text-center border-primary"
									textCustom="text-sm text-primary"
									variant="hidden"
								>
									Abrir Instagram
								</Button>
							</div>
							<div className="flex flex-col gap-2">
								<div className="text-center text-[#7f7f7f] text-xs font-semibold font-bahnschrift leading-none tracking-tight">
									Compartilhe nas redes sociais
								</div>
								<div className="flex flex-row items-center justify-center gap-2">
									{shared.map((social, index) => (
										<div
											key={index}
											onClick={async () => {
												if (
													Capacitor.isNativePlatform()
												) {
													if (isSaving) {
														return;
													}
													const fileShow =
														await handleDownload();
													console.log(
														'Log - fileShow:',
														fileShow
													);

													if (fileShow) {
														await Share.share({
															title: `Compartilhar no ${social.title}`,
															text: textShared,
															files: [fileShow],
															dialogTitle:
																'Compartilhar com...',
														});
													}
												} else {
													if (isMobileWeb()) {
														if (navigator.share) {
															navigator
																.share({
																	title: `Compartilhar no ${social.title}`,
																	text: textShared,
																})
																.then(() =>
																	console.log(
																		'Successful share'
																	)
																)
																.catch(
																	(error) =>
																		console.log(
																			'Error sharing',
																			error
																		)
																);
														}
													} else {
														const url = generateUrl(
															social.title,
															textShared
														);
														window.open(
															url,
															'_blank'
														);
													}
												}
											}}
											className="cursor-pointer"
										>
											{social.icon}
										</div>
									))}
								</div>
							</div>
						</div>
					</ModalEnroll>
				</>
			)}
		</EnrollContext.Provider>
	);
};

export const useEnrollContext = (): EnrollContextType => {
	const context = useContext(EnrollContext);
	if (context === undefined) {
		throw new Error(
			'useEnrollContext must be used within a EnrollProvider'
		);
	}
	return context;
};
