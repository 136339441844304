import { Capacitor } from '@capacitor/core';

// VERSAO DENTRO DO APP (PROFILE)
const APP_VERSION_IOS = '1.0.13.117';
const APP_VERSION_ANDROID = '1.0.13.117';
const APP_VERSION_WEB = '1.0.117';

// VERSAO DA LOJA
const APP_VERSION_IOS_STORE = '1.0.13';
const APP_VERSION_ANDROID_STORE = '1.0.13';
const APP_VERSION_ANDROID_APK = '1.0.13';

export const isApk = false;

export const APP_VERSION =
	Capacitor.getPlatform() === 'ios'
		? APP_VERSION_IOS
		: Capacitor.getPlatform() === 'android'
		? APP_VERSION_ANDROID
		: APP_VERSION_WEB;

export const APP_VERSION_STORE =
	Capacitor.getPlatform() === 'web'
		? ''
		: Capacitor.getPlatform() === 'ios'
		? APP_VERSION_IOS_STORE
		: Capacitor.getPlatform() === 'android' && isApk
		? APP_VERSION_ANDROID_APK
		: APP_VERSION_ANDROID_STORE;
